import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/services.css'

function Services() {



    const images = require.context('../icons', true);

    return (
        <div className=''>
            <div className="parent mx-auto">
                <div className="div1s">
                    <img src={images('./recluta.png')} className='services_icons mx-auto' alt='img_D&S'/>
                    <h4 className='titulos_servicios'>RECLUTAMIENTO Y SELECCION DE CANDIDATOS</h4>
                    <ul className="ls_services">
                        <li >Publicación de Vacantes</li>
                        <li>Reclutamiento</li>
                        <li>Perfilamiento de hojas de vida</li>
                        <li>Pruebas Psicotécnicas</li>
                        <li>Entrevistas</li>
                        <li>Informe de Selección</li>
                    </ul>
                </div>
                <div className="div2s">
                    <img src={images('./pruebas.png')} className='services_icons mx-auto' alt='img_D&S'/>
                    <h4 className='titulos_servicios'>EVALUACIÓN DE CANDIDATOS</h4>
                    <ul className="ls_services">
                        <li>Pruebas Virtuales</li>
                        <li>Referienciación</li>
                    </ul>
                </div>
                <div className="div3s">
                    <img src={images('./confiar.png')} className='services_icons mx-auto' alt='img_D&S'/>
                    <h4 className='titulos_servicios'>ESTUDIO DE CONFIABILIDAD</h4>
                    <ul className="ls_services">
                        <li>Estudio de Confiabilidad</li>
                        <li>Predicto</li>
                        <li>Plestimografia</li>
                    </ul>
                </div>
                <div className="div4s">
                    <img src={images('./depura.png')} className='services_icons mx-auto' alt='img_D&S'/>
                    <h4 className='titulos_servicios'>DEPURACIÓN Y CONCILIACIÓN DE CARTERA DE SEGURIDAD SOCIAL</h4>
                    <ul className="ls_services">
                        <li>Fondos de Pensiones</li>
                        <li>Colpensiones</li>
                        <li>Administradora de Riesgos Laborales</li>
                        <li>Entidades Promotoras de Salud</li>
                        <li>Cajas de Compensacion Familiar</li>
                    </ul>
                </div>
                <div className="div5s">
                    <img src={images('./afiliacion.png')} className='services_icons mx-auto' alt='img_D&S'/>
                    <h4 className='titulos_servicios'>AFILIACIÓN Y TRASLADO SISTEMA DE SEGURIDAD SOCIAL</h4>
                    <ul className="ls_services">
                        <li>Fondos de Pensiones</li>
                        <li>Administradora de Riesgos Laborales</li>
                        <li>Entidades Promotoras de Salud</li>
                        <li>Cajas de Compensación Familiar</li>
                    </ul>
                </div>
                <div className="div6s">
                    <img src={images('./oil.png')} className='services_icons mx-auto' alt='img_D&S'/>
                    <h4 className='titulos_servicios'> SERVICIOS ESPECIALIZADOS OIL & GAS</h4>
                    <ul className="ls_services">
                        <li>Logística de Personal</li>
                        <li>Logística de Transporte</li>
                        <li>Personal Convencional</li>
                    </ul>
                </div>

            </div>
        </div>
        
        
    )
}

export default Services;