import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/frecuentes.css'

function Frecuentes(){

    const images = require.context('../imgs', true);

    return (
        <div className="mains">
            <div className="title">
                <i className="bi bi-book-fill" style={{color: "#2f5597",fontSize: 200}}></i>
                <h1>Preguntas Frecuentes</h1>
            </div>
            <div className="preguntas">
                <h2>Contratación</h2>
                <section>
                    <details>
                        <summary>Soy trabajador de Desarrollo y Servicios Empresariales, ¿Cómo solicito mi certificación laboral?</summary>
                        <p>Para solicitar una certificación laboral, por favor, haga clic <a className="a_click" href="tramites.html">aquí</a></p>
                        <p>Si necesitas una certificación con promedio de salario u otro tema específico, solicítala al correo: contratacion@dyssas.com.co</p>
                    </details>
                </section>
                <h2>Nómina</h2>
                <section>
                    <details>
                        <summary>Soy trabajador de Desarrollo y Servicios Empresariales, ¿Cómo solicito mi desprendible de nómina?</summary>
                        <p>Para solicitar un desprendible de nómina, por favor, haga clic <a className="a_click" href="tramites.html">aquí</a></p>
                    </details>
                    <details>
                        <summary>¿Cómo puedo obtener el certificado de pago de mi seguridad social?</summary>
                        <p>Para obtener el certificado de pago de seguridad social, por favor, haga clic <a className="a_click" href="tramites.html">aquí</a></p>
                    </details>
                    <details>
                        <summary>Soy trabajador de Desarrollo y Servicios Empresariales, ¿Cómo solicito mi certificado de Ingresos y Retenciones?</summary>
                        <p>Para obtener el certificado de Ingresos y Retenciones, por favor, haga clic <a className="a_click" href="tramites.html">aquí</a></p>
                    </details>
                </section>
                <h2>Selección</h2>
                <section>
                    <details>
                        <summary>¿Dónde puedo hacer llegar mi Hoja de Vida?</summary>
                        <p>- A los correos:<br/> <b>seleccion@dyssas.com.co</b> <br/></p>
                    </details>
                    <details>
                        <summary>¿Dónde puedo ver ofertas laborales?</summary>
                        <p>En nuestra página de "Trabaja con nosotros" o simplemente haz clic <a className="a_click" href="vacantes.php">aquí</a></p>
                    </details>
                    <details>
                        <summary>¿Desarrollo y Servicios Empresariales efectúa los procesos de selección sin ningún costo para el aspirante?</summary>
                        <p>NO</p>
                    </details>
                    
                </section>
                <h2>Salud y Seguridad en el trabajo</h2>
                    <section>
                        <details>
                            <summary>¿Cómo reporto un accidente de trabajo?</summary>
                            <p>Si sufre un accidente de trabajo debe reportar a su jefe o supervisor de área, inmediatamente ocurrido el evento, este realizará el reporte a la dependencia correspondiente y lo direccionará al centro médico pertinente.   
                            En caso de que no se encuentre su jefe o supervisor de área, puede reportarlo al correo sgsst@dyssas.com.co y comunicándose al número 313 407 6177 con el encargado de Seguridad y Salud en el Trabajo de Desarrollo y Servicios Empresariales</p>
                        </details>
                        <details>
                            <summary>¿Cómo Radico incapacidades por Accidente de Trabajo?</summary>
                            <p>Las incapacidades por accidente de trabajo debe enviarlas al correo sgsst@dyssas.com.co, el Encargado de SST es quien realizará el proceso ante la ARL</p>
                        </details>
                        <details>
                            <summary>¿Cómo pido mis citas por la ARL?</summary>
                            <p>Puede comunicarse con la ARL al #247 o al número 601 4235757, allí le indicarán el proceso de citas pendientes y programación según corresponda.</p>
                        </details>
                        <details>
                            <summary>¿Cómo solicito mi carnet de la ARL?</summary>
                            <p>Como afiliado a la ARL usted puede realizarlo en la página de Axa Colpatria directamente.</p>
                        </details>
                    </section>
                <h2>Autogestión</h2>
                    <section>
                        <details>
                            <summary>Soy trabajador de Desarrollo y Servicios Empresariales, ¿Cómo solicito mi usuario?</summary>
                            <p>Para obtener el usuario para ingresar al portal, debes ser trabajador activo, al momento de tu contratación pasarán 5 días hábiles en los cuales recibirás un correo con tu usuario y contraseña para poder acceder al portal de colaboradores, haga clic <a className="a_click" href="tramites.html">aquí</a></p>
                        </details>
                    </section>
            </div>
        </div>
    );

}

export default Frecuentes;