import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Fade } from "react-awesome-reveal";
import '../styles/portal.css'

function Portal() {

    const images = require.context('../icons', true);

    return (
        
        <div class="body-portal">
            <div class="first-portal">
                <div className="card shadow portal-title">
                    <div className="card-body edit">
                            <h1 className='card-title title_portal'><Fade delay={1e2} cascade damping={1e-1}>PORTAL WEB</Fade></h1>
                    </div>
                </div>
                <div className="card shadow">
                    <div className="card-body edit">
                        <Fade delay={1e3} >
                            <iframe className="rounded mx-auto video"  src="https://www.youtube.com/embed/_zGhkeGe8zI?si=4QYzK4KE82vbwswp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Fade>
                    </div>
                </div>
            </div>
            <div class="second-portal">
                <a className='portal-a' href="http://181.57.171.138:8081/Portal_DYS/Acceso/Login.aspx">
                    <div className="card shadow cp">
                        <Fade delay={1e3}>
                            <img className='card-img-top portal-icons mx-auto' src={images("./certificado.png")} alt="" />
                            <div className="card-body">
                                <h3 className='card-title text-center'>Certificado <br /> Laboral</h3>
                            </div>
                        </Fade>
                    </div>
                </a>
                <a className='portal-a' href="http://181.57.171.138:8081/Portal_DYS/Acceso/Login.aspx">
                    <div className="card shadow cp">
                        <Fade delay={1e3}>
                            <img className='card-img-top portal-icons mx-auto' src={images("./cheque.png")} alt="" />
                            <div className="card-body">
                                <h3 className='card-title text-center'>Desprendible <br /> de Nomina</h3>
                            </div>
                        </Fade>
                    </div>
                </a>
                <a className='portal-a' href="http://181.57.171.138:8081/Portal_DYS/Acceso/Login.aspx">
                    <div className="card shadow cp">
                        <Fade delay={1e3}>
                            <img className='card-img-top portal-icons mx-auto' src={images("./impuesto.png")} alt="" />
                            <div className="card-body">
                                <h3 className='card-title text-center'>Certificado de Ingresos y Retenciones</h3>
                            </div>
                        </Fade>
                    </div>
                </a>
                <a className='portal-a' href="https://www.suaporte.com.co/Web/faces/pages/comprobantes/consultadirecta/consultaDirectaLogin.xhtml">
                    <div className="card shadow cp">
                        <Fade delay={1e3}>
                            <img className='card-img-top portal-icons mx-auto' src={images("./social.png")} alt="" />
                            <div className="card-body">
                                <h3 className='card-title text-center'>Certificado de Seguridad<br/> Social</h3>
                            </div>
                        </Fade>
                    </div>
                </a>
            </div>
        </div>

    )
}

export default Portal;