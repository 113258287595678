import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/noticia.css'

function Noticia() {



    // const images = require.context('../icons', true);
    const images2 = require.context('../imgs', true);

    return (
        
        <div className='mainn'>
            <div className='section_one'>
                <img src={images2('./titulo_autocuidado.jpg')} className="img_title" alt=''/>
                <h1 className='h1_noticia'>AUTOCUIDADO</h1>
            </div>
            <div className="div2_notic">
                <p className='p_notic'>
                    Son las actividades que desarrolla una persona para mejorar su calidad de vida.
                    <ul>
                        <li>Alimentación</li>
                        <li>Buen dormir</li>
                        <li>Ejercicio</li>
                    </ul>
                    <h2 className='subtitle_notic'>DECISIONES, ACCIONES Y ESTRATEGIAS</h2>
                    La que asume la persona para mejorar su estado de salud.

                    <h2 className='subtitle_notic'>PRINCIPIO DEL AUTOCUIDADO</h2> 
                    Acto de vida que permite a las personas convertirse en responsable de sus propias acciones.
                    <h2 className='subtitle_notic'>El AUTOCUIDADO ESTA FORMADO POR UNA COMBINACION DE</h2>
                    <ul>
                        <li>Actitud</li>
                        <li>Comportamiento Seguro</li>
                        <li>Control</li>
                    </ul>

                </p>
            </div>
            <div className='lado_imagen_uno'>
                <p className='p_notic'>
                    <h2 className='subtitle_notic'>ACTITUD</h2>
                    Forma de pensar, la forma de visualiza una situación determinada.
                    <ul>
                        <li>Mantenerse alerta (Atento al trabajo que se esté realizando) </li>
                        <li>Seriedad (las reglas y los procedimientos de seguridad)</li>
                    </ul>
                    <h2 className='subtitle_notic'>COMPORTAMIENTO SEGURO</h2>
                    <ul>
                        <li>Ajustarse a las reglas (A los procedimientos de seguridad) </li>
                        <li>Usar EPP (Usar el equipo de protección personal adecuado) </li>
                        <li>Ayudar (Compañeros de trabajo y familiares a entender la importancia que tienen las prácticas de seguridad en el trabajo)</li>
                    </ul>
                    </p>
            </div>
            <div className='imagen_uno'>
                <img src={images2('./autocuidado_uno.jpg')} alt="" />
            </div>
            <div className='divisor_imagen'>
                <p className='p_notic'>
                    <h2 className='subtitle_notic'>CONTROL</h2>
                    Se encarga de todos las cosas que le rodean, como el lugar de trabajo y la forma en que realiza la labor para que éstos sean seguros.
                    <h2 className='subtitle_notic'>COMO INICIAR ACTIVIDAD FISICA DESPUES DE LAS VACACIONES</h2>
                    A veces empezar es lo más difícil. Sin embargo, hacerlo precipitadamente puede resultar peligroso y causarte lesiones. Un error común es pretender hacer la misma rutina desde el primer día de ejercicio y eso no resulta conveniente.
                
                    <ul className='ul_decimal'>
                        <li>El cuerpo requiere de tiempo para adaptarse al ejercicio, además para que los músculos y huesos se fortalezcan, adquieran tonicidad y flexibilidad. Por ello es importante empezar progresivamente y sin exigirte demasiado, sobre todo si has tenido una lesión</li>
                        <li>Si el motivo por el que dejaste de hacer ejercicio fue por enfermedad, es importante consultar a tu médico antes de iniciar con tu rutina. No olvides preguntarle si debes tener algún cuidado en especial. </li>
                        <li>Cualquiera que sea tu meta, escríbela y déjala en un lugar visible.</li>
                    </ul>
                    
                </p>
            </div>
            <div className='lado_imagen_dos'>
                <p className='p_notic'>
                    <ol className='ul_decimal' start='4'>
                        <li>Arma un plan de ejercicio; define días, horarios y lugar a donde irás a practicar. Busca nuevas formas de ejercitarte</li>
                        <li>Aprende a escuchar a tu cuerpo y acepta tus propias limitaciones.</li>
                        <li>Cuida tus músculos. Inicia con movimientos lentos; si entrenarás con pesas, usa las más pequeñas para mejorar tu resistencia. Poco a poco aumenta el peso y las repeticiones dependiendo del resultado que quieras obtener.</li>
                    </ol>
                </p>
            </div>
            <div className='imagen_dos'>
                <img src={images2('./autocuidado_dos.jpg')} alt="" />
            </div>
            <div className='final_blog'>
                <p className='p_notic'>
                    <h2 className='subtitle_notic'>CONTROL</h2>
                    Este folleto se desarrolló teniendo en cuenta los resultados de las investigaciones de los accidentes de trabajo, como acción preventiva.
                    Pensando en el bienestar de cada uno de los trabajadores y sus familias. 
                </p>
            </div>
        </div>
        
    )
}

export default Noticia;