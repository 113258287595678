import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css"

function Footer() {
    return (
        <footer className=" text-center shadow">
            <div className="p-4">
                
                <section className="mb-4 color_number">
                    <p>
                        Estamos comprometidos con el bienestar y desarrollo de nuestros colaboradores, clientes, proveedores y aliados estratégicos.
                    </p>
                </section>

            </div>

            <div
                className="text-center p-3"
            >
                © 2023 Copyright: 
                <a className="text-reset fw-bold" href="../index">
                    dys.com.co
                </a>
            </div>
        </footer>
    );
}

export default Footer;
