import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/contactanos.css'

function Contactanos() {



    const images = require.context('../icons', true);
    const images2 = require.context('../imgs', true);

    return (
        <div>
            <section className="contactacnos_conetenedor">
                <div className="contenedor">
                    <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active"
                                aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                                aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3"
                                aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4"
                                aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5"
                                aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <img src={images2('./candidatos.jpg')} className="d-block w-75 mx-auto img_areas shadow-lg" alt="..." />
                                <div className="carousel-caption d-md-block">
                                    <img src={images('./contactanos.png')} alt="" className="img_icon" />
                                    <h1 className="tit_areas">CANALES DE CONTACTO</h1>
                                    <p className="descrip">Horario de atención Presencial:</p>
                                    <p className="descrip">Bogotá: Lunes a Viernes 8:00 am - 5:00 pm</p>
                                    <p className="descrip">Direccion:</p>
                                    <p className="descrip">Bogotá: Carrera 16 # 35 - 22</p>
                                    <p className="descrip">Telefono:</p>
                                    <p className="descrip">PBX Bogotá: 601 487 1200</p>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="10000">
                                <img src={images2('./candidatos.jpg')} className="d-block w-75 mx-auto img_areas shadow-lg" alt="..." />
                                <div className="carousel-caption d-md-block">
                                    <img src={images('./contratacion.png')} alt="" className="img_icon" />
                                    <h1 className="tit_areas">CONTRATACIÓN</h1>
                                    <p className="descrip">Procesos de contratacion</p>
                                    <p className="descrip">Afiliaciones</p>
                                    <p className="descrip">Subsidio Familiar</p>
                                    <p className="descrip">Renuncias</p>
                                    <p className="descrip">Copias de Contratos</p>
                                    <p className="descrip">contratacion@dyssas.com.co</p>
                                    <p className="descrip">PBX: 601 487 1200 EXT: 102-104</p>
                                    <p className="descrip">Celular: 318 359 0646</p>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="10000">
                                <img src={images2('./candidatos.jpg')} className="d-block w-75 mx-auto img_areas shadow-lg" alt="..." />
                                <div className="carousel-caption d-md-block">
                                    <img src={images('./seleccion.png')} alt="" className="img_icon" />
                                    <h1 className="tit_areas">SELECCIÓN</h1>
                                    <p className="descrip">Procesos de Selección</p>
                                    <p className="descrip">Postulaciones</p>
                                    <p className="descrip">Entregas de hoja de vida</p>
                                    <p className="descrip">Reclutamiento</p>
                                    <p className="descrip">Solicitud de Personal</p>
                                    <p className="descrip">seleccion@dyssas.com.co</p>
                                    <p className="descrip">PBX: 601 487 1200 EXT: 107-126</p>
                                    <p className="descrip">Celulares: 318 651 4041 / 311 847 0368</p>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="10000">
                                <img src={images2('./candidatos.jpg')} className="d-block w-75 mx-auto img_areas shadow-lg" alt="..." />
                                <div className="carousel-caption d-md-block">
                                    <img src={images('./nomina.png')} alt="" className="img_icon" />
                                    <h1 className="tit_areas">NÓMINA</h1>
                                    <p className="descrip">Procesos de Nómina</p>
                                    <p className="descrip">Liquidaciones</p>
                                    <p className="descrip">Comprobantes de Nómina</p>
                                    <p className="descrip">
                                        Extractos de Planilla de Seguridad Social
                                    </p>
                                    <p className="descrip">nomina@dyssas.com.co</p>
                                    <p className="descrip">PBX: 601 487 1200 EXT: 112-111</p>
                                    <p className="descrip">Celular: 317 638 3767</p>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="10000">
                                <img src={images2('./candidatos.jpg')} className="d-block w-75 mx-auto img_areas shadow-lg" alt="..." />
                                <div className="carousel-caption d-md-block">
                                    <img src={images('./sgsst.png')} alt="" className="img_icon" />
                                    <h1 className="tit_areas">SEGURIDAD Y SALUD EN EL TRABAJO</h1>
                                    <p className="descrip">Reportes de Accidentes de Trabajo</p>
                                    <p className="descrip">Informacion casos médicos ARL y EPS</p>
                                    <p className="descrip">sgsst@dyssas.com.co</p>
                                    <p className="descrip">PBX: 601 487 1200 EXT: 106</p>
                                    <p className="descrip">Celular: 313 407 6177</p>
                                </div>
                            </div>
                        
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>
            <section className="ubicacion">
                <div className="contenedor_titulo">
                    <h2 className="titulo_ubicacion">Ubicación</h2>
                </div>
                <div className="contenido_ubicacion">
                    <div className="">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.830442581548!2d-74.07321838255615!3d4.624321800000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f99872ad15143%3A0xf089353518d7981f!2sCra.%2016%20%2335-22%2C%20Teusaquillo%2C%20Bogot%C3%A1!5e0!3m2!1ses-419!2sco!4v1681084205929!5m2!1ses-419!2sco"
                            width="720" height="200" loading="lazy" className="mapa" title='video'></iframe>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contactanos;