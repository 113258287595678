import React from 'react';
import { Link } from 'react-router-dom'; // Importa Link
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/header.css';

function Header() {
    const images = require.context('../icons', true);

    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light shadow">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={images('./logodys.png')} alt="" className='icon' />
                    </Link>
                    <button 
                        className="navbar-toggler" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#navbarToggleExternalContent" 
                        aria-controls="navbarToggleExternalContent" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarToggleExternalContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        </ul>
                        <form className="d-flex bg-white">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">NOSOTROS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/services">SERVICIOS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/frecuentes">PREGUNTAS FRECUENTES</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/seleccion">TRABAJA CON NOSOTROS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/noticia">ENTERATE</Link>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
