import { useTable, usePagination } from "react-table";
import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "../styles/vacantes.css";

function Seleccion() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchCSVData();
    }, []); // The empty array ensures that this effect runs only once

    const fetchCSVData = () => {
        const csvUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vRfSOcbrMqipKzMnjBB8LkKUA9dXXn7mI9oK-f3yrhu0bTHJBOx8HKxu4BbD_yZCQ/pub?gid=670261784&single=true&output=csv"; // Replace with your Google Sheets CSV file URL

        axios
            .get(csvUrl)
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);
                setData(parsedCsvData); // Set the fetched data in the component's state
            })
            .catch((error) => {
                console.error("Error fetching CSV data:", error);
            });
    };

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);
        const headers = rows[0].split(",");
        const data = [];
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(",");
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
            }
            data.push(rowObject);
        }
        return data;
    }

    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            canPreviousPage,
            canNextPage,
            pageOptions,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
                initialState: { pageIndex: 0, pageSize: 10 },
            },
            usePagination
        );

        return (
            <div className="table_vacantes">
                <table {...getTableProps()} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className='titulos' {...column.getHeaderProps()} >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => { // Usar page aquí
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className="celdas" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                    <td>
                                        <button
                                            onClick={() => handleProfileLink(row.original.link)}
                                            style={{ cursor: "pointer" }}
                                            className="btn btn-info"
                                        >
                                            APLICAR
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-info">
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-info">
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-info">
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-info">
                        {'>>'}
                    </button>{' '}
                    <span>
                        Página{' '}
                        <strong>
                            {pageIndex + 1} de {pageOptions.length}
                        </strong>{' '}
                    </span>
                    {/* <span>
                        | Ir a la página:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{ width: '50px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[5, 10, 20].map(size => (
                            <option key={size} value={size}>
                                Mostrar {size}
                            </option>
                        ))}
                    </select> */}
                </div>
            </div>
        );
    }

    const handleProfileLink = (link) => {
        window.location.href = link;
    };

    const columns = React.useMemo(
        () => [

        {
            Header: "Ciudad",
            accessor: "ciudad",
        },
        {
            Header: "Vacante",
            accessor: "vacante",
        },
        {
            Header: "Estado",
            accessor: "status",
        },

        ],
        []
    );

    const filteredData = data.filter((vacan) => vacan.status === "RECLUTAMIENTO");

    // console.log(filteredData); // Asegúrate de que hay datos

    return (
        <div className="body_vacantes">
            <Table columns={columns} data={filteredData} />
            <div className="registra_hoja">
                <h1>Registra tu hoja de vida</h1>
                <p>Sigue el paso a paso del video para realizar un registro exitoso</p>
                <div className="video_seleccion">
                    <iframe className="frame shadow" src="https://www.youtube.com/embed/izJrd7373CQ?si=PfYYDXoJ25_mjjpu?autoplay=1&mute=1&loop=1"
                    title="YouTube video player" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
                
                <button className="btn btn-info p-3" onClick={() => handleProfileLink("http://181.57.171.138:8081/Portal_DYS/Acceso/Login.aspx")}>Registrate!</button>
            </div>
        </div>
    );
}

export default Seleccion;
