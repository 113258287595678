import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/about.css'

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function About() {


    return(

        <div className="body-about p-4">
            
            <div className="i5">
                <div className="card text-center shadow ">
                    <div className="card-body about">
                        <h3 className="card-title">Acerca de DyS SAS</h3>
                        <p className="card-text h4"> En DyS SAS, entendemos que nuestros clientes requieren soluciones personalizadas que generen valor y que les permitan dedicar su capacidad instalada al desarrollo del negocio, por lo que ponemos a su disposición nuestra experiencia de más de 15 años diseñando soluciones especializadas de outsourcing integral.                        </p>
                    </div>
                </div>
                <div className="card text-center shadow ">
                    <div className="card-body about">
                        <h3 className="card-title h3">¿Qué nos diferencia?</h3>
                        <p className="card-text h4">Nuestro equipo de profesionales está comprometido en proporcionar un servicio de alta calidad para optimizar procesos, reducir costos operativos y potenciar la competitividad de nuestros aliados, mediante la tercerización de procesos en todos los niveles organizacionales.</p>
                    </div>
                </div>
            </div>
            <div className='slide_div'>
                <div className='card shadow card_slide'>
                    <div className="card-body">
                        {/* <h3 className="card-title">Nuestros Clientes</h3> */}
                        <CarouselProvider 
                        className='slider'
                        naturalSlideWidth={125}
                        naturalSlideHeight={9}
                        totalSlides={4}
                        isPlaying={true}
                        infinite={true}
                        >
                            <Slider>
                                <Slide index={0} className='slide'>
                                    {/* <h1 className="card-title h3">+</h1> */}
                                    <h3 className="card-title h3 color_number">+130</h3>
                                    <h3 className="card-title h3">Colaboradores</h3>

                                </Slide>
                                <Slide index={1} className='slide'>
                                    <h3 className="card-title h3 color_number">+10</h3>
                                    <h3 className="card-title h3">Regiones</h3>

                                </Slide>
                                <Slide index={2} className='slide'>
                                    <h3 className="card-title h3 color_number">+100</h3>
                                    <h3 className="card-title h3">Proyectos Atendidos</h3>
                                </Slide>
                                <Slide index={0} className='slide'>
                                    {/* <h1 className="card-title h3">+</h1> */}
                                    <h3 className="card-title h3 color_number">1999</h3>
                                    <h3 className="card-title h3">Inicio de Operaciones</h3>

                                </Slide>
                            </Slider>
                        </CarouselProvider>
                    </div>
                </div>
            </div>
            
        </div>

    );

}

export default About;