import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import MainWrapper from './layouts/MainWrapper';
import Body from './pages/body';
import About from './pages/about';
import Portal from './pages/portal';
import Services from './pages/services';
import Frecuentes from './pages/frecuentes';
import Contactanos from './pages/contactanos';
import Noticia from './pages/noticia';
import Seleccion from './components/pruebas';
import PruebaBtn from './components/pruebaBtn';

import './styles/App.css';

function App() {
  return (
    <HashRouter>
      <MainWrapper>
        <div className="root"> 
          <Header />
          <Routes>
            <Route path='/' element={<Body />} />
            <Route path='/about' element={<About />} />
            <Route path='/portal' element={<Portal />} />
            <Route path='/services' element={<Services />} />
            <Route path='/frecuentes' element={<Frecuentes />} />
            <Route path='/contactanos' element={<Contactanos />} />
            <Route path='/noticia' element={<Noticia />} />
            <Route path='/seleccion' element={<Seleccion />} />
            <Route path='/pruebabtn' element={<PruebaBtn />} />
          </Routes>
          <Footer />
        </div>
      </MainWrapper>
    </HashRouter>
  );
}

export default App;